var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "dashboard-ecommerce" } },
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { xl: "4", md: "6" } },
            [
              _c("ecommerce-medal", {
                attrs: { name: _vm.firstName, amount: _vm.amountApproved },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xl: "8", md: "6" } },
            [_c("ecommerce-statistics", { attrs: { data: _vm.orders } })],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c("app-echart-stacked-area", {
                attrs: { "option-data": _vm.option },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { lg: "8" } },
            [
              _c("ecommerce-company-table", {
                attrs: { "table-data": _vm.data.companyTable },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [_c("ecommerce-meetup", { attrs: { data: _vm.data.meetup } })],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [_c("ecommerce-browser-states")],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [
              _c("ecommerce-goal-overview", {
                attrs: { data: _vm.data.goalOverview },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [
              _c("ecommerce-transactions", {
                attrs: { data: _vm.data.transactionData },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }