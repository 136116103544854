<template>
  <b-card
    v-if="name || amount"
    class="card-congratulation-medal"
  >
    <h5>🎉 {{ name }}!</h5>
    <b-card-text>
      Total em pedidos aprovados neste mês
    </b-card-text>
    <h3 class="mb-75 text-success mt-2 pt-50">
      R$ {{ amount ? amount : '0' }}
    </h3>
    <a
      href="/orders"
      class="btn btn-primary"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    >
      Ver vendas
  </a>
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    name: {
      typ: String,
      require: true,
    },
    amount: {
      typ: String,
      require: true,
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
