import { render, staticRenderFns } from "./EcommerceMedal.vue?vue&type=template&id=347bde9b"
import script from "./EcommerceMedal.vue?vue&type=script&lang=js"
export * from "./EcommerceMedal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/vercel/path0/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('347bde9b')) {
      api.createRecord('347bde9b', component.options)
    } else {
      api.reload('347bde9b', component.options)
    }
    module.hot.accept("./EcommerceMedal.vue?vue&type=template&id=347bde9b", function () {
      api.rerender('347bde9b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/ecommerce/EcommerceMedal.vue"
export default component.exports