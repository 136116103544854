<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :name="firstName" :amount="amountApproved" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics :data="orders" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- <b-col lg="4">
        <b-row class="match-height">
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col> -->

      <!-- Revenue Report Card -->
      <b-col lg="12">
        <app-echart-stacked-area :option-data="option" />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col>
      <!--/ Developer Meetup Card -->

      <!-- Browser States Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-browser-states />
      </b-col>
      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col>
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col>
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'
import moment from 'moment';
import { mounted } from 'vue-echarts'

const startOfMonth = moment().startOf('month').format('DD');
const endOfMonth = moment().endOf('month').format('DD');

export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    AppEchartStackedArea,
  },
  data() {
    return {
      data: {},
      orders: [],
      congratulations: '',
      firstName: '',
      amountApproved: 0,
      orderProducts: {},
      series: [],
      option: {
        title: {
          text: 'Vendas por categoria de produto',
        },
        dataZoom: [
          {
            type: 'inside'
          },
          {
            type: 'slider'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          left: '0',
        },
        grid: {
          top: 60,
          left: 10,
          right: 0,
          bottom: 15,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        series: []
      },
    }
  },
  async mounted() {
    this.getData();
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        const userData = getUserData()
        const firstName = userData.fullName.split(' ')
        this.firstName = firstName[0]
      })
  },
  methods: {
    numFormatter(num) {   
      num = parseInt(num);
     
      if(num > 999 && num < 1000000){
          return (num/1000).toFixed(1) + 'K';
      }else if(num > 1000000){
          return (num/1000000).toFixed(1) + 'M';
      }else if(num < 900){
          return num;
      }
    },
    formatCurrency(value){
      if (typeof value !== "number") {
        return value;
      }

      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BRL'
      });

      return formatter.format(value);
    },
    async getData() {
      this.$store
        .dispatch('Dashboard/index')
        .then(res => {
          this.amountApproved = this.numFormatter(res.orders.approved.total)
          this.orders = [
            {
              icon: 'hand-thumbs-up',
              color: 'light-success',
              title: `${this.numFormatter(res.orders.approved.total)}`,
              subtitle: 'Pago',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'hourglass-split',
              color: 'light-info',
              title: `${this.numFormatter(res.orders.pending.total)}`,
              subtitle: 'Pendente',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'hand-thumbs-down',
              color: 'light-danger',
              title: `${this.numFormatter(res.orders.rejected.total)}`,
              subtitle: 'Rejeitado',
              customClass: 'mb-2 mb-sm-0',
            },
            {
              icon: 'cart2',
              color: 'light-warning',
              title: `${this.numFormatter(res.orders.carts.total)}`,
              subtitle: 'Carrinhos',
              customClass: 'mb-2 mb-sm-0',
            },
          ]

          if (res.ordersProducts.categories.length) {
            const seriesData = [];
              
            res.ordersProducts.categories.forEach((category) => {
              const orderDays = [];

              res.ordersProducts.days.forEach(_day => {
                let checkAddOrder = false;
                category.orders.filter((order) => {
                  if (order.date === _day) {
                    checkAddOrder = true
                    orderDays.push(order.total)
                  }
                })

                if (!checkAddOrder) {
                  orderDays.push(0)
                }
              })

              seriesData.push({
                name: `${category.title}`,
                type: 'line',
                stack: 'Total',
                showSymbol: false,
                areaStyle: {},
                lineStyle: {
                  width: 0,
                },
                data: orderDays,
                symbol: 'R$ ',
              })
            });
         
            this.option.xAxis[0].data = Object.values(res.ordersProducts.days)
            this.option.series = seriesData            
          }
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.echarts {
  width: 100%;
}
</style>
