var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "b-card",
        { staticClass: "card-statistics", attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            [
              _c("b-card-title", [_vm._v("Vendas")]),
              _c("b-card-text", { staticClass: "font-small-2 mr-25 mb-0" }, [
                _vm._v(" Referente ao mês atual "),
              ]),
            ],
            1
          ),
          _c(
            "b-card-body",
            {
              staticClass: "statistics-body",
              staticStyle: { "padding-top": "10px!important" },
            },
            [
              _c(
                "div",
                { staticClass: "form-row" },
                _vm._l(_vm.data, function (item) {
                  return _c(
                    "b-col",
                    {
                      key: item.icon,
                      class: item.customClass,
                      attrs: { xl: "3", sm: "6" },
                    },
                    [
                      _c(
                        "b-media",
                        { attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-media-aside",
                            { staticClass: "mr-1" },
                            [
                              _c(
                                "b-avatar",
                                { attrs: { size: "48", variant: item.color } },
                                [
                                  _c("i", {
                                    staticClass: "bi",
                                    class: "bi-" + item.icon,
                                    staticStyle: { "font-size": "1.5rem" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-media-body",
                            { staticClass: "my-auto" },
                            [
                              _c(
                                "h4",
                                { staticClass: "font-weight-bolder mb-0" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.title !== "undefined"
                                          ? item.title
                                          : "0"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-card-text",
                                { staticClass: "font-small-3 mb-0" },
                                [_vm._v(" " + _vm._s(item.subtitle) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }