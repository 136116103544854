var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "b-card",
        { staticClass: "card-developer-meetup", attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "bg-light-primary rounded-top text-center" },
            [
              _c("b-img", {
                attrs: {
                  src: require("@/assets/images/illustration/email.svg"),
                  alt: "Meeting Pic",
                  height: "170",
                },
              }),
            ],
            1
          ),
          _c(
            "b-card-body",
            [
              _c(
                "div",
                { staticClass: "meetup-header d-flex align-items-center" },
                [
                  _c("div", { staticClass: "meetup-day" }, [
                    _c("h6", { staticClass: "mb-0" }, [_vm._v(" THU ")]),
                    _c("h3", { staticClass: "mb-0" }, [_vm._v(" 24 ")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "my-auto" },
                    [
                      _c("b-card-title", { staticClass: "mb-25" }, [
                        _vm._v(" Developer Meetup "),
                      ]),
                      _c("b-card-text", { staticClass: "mb-0" }, [
                        _vm._v(" Meet world popular developers "),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._l(_vm.data.mediaData, function (media) {
                return _c(
                  "b-media",
                  { key: media.avatar, attrs: { "no-body": "" } },
                  [
                    _c(
                      "b-media-aside",
                      { staticClass: "mr-1" },
                      [
                        _c(
                          "b-avatar",
                          {
                            attrs: {
                              rounded: "",
                              variant: "light-primary",
                              size: "34",
                            },
                          },
                          [
                            _c("feather-icon", {
                              attrs: { icon: media.avatar, size: "18" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("b-media-body", [
                      _c("h6", { staticClass: "mb-0" }, [
                        _vm._v(" " + _vm._s(media.title) + " "),
                      ]),
                      _c("small", [_vm._v(_vm._s(media.subtitle))]),
                    ]),
                  ],
                  1
                )
              }),
              _c(
                "b-avatar-group",
                { staticClass: "mt-2 pt-50", attrs: { size: "34px" } },
                [
                  _vm._l(_vm.data.avatars, function (avatar) {
                    return _c("b-avatar", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.bottom",
                          value: avatar.fullName,
                          expression: "avatar.fullName",
                          modifiers: { hover: true, bottom: true },
                        },
                      ],
                      key: avatar.avatar,
                      staticClass: "pull-up",
                      attrs: { src: avatar.avatar },
                    })
                  }),
                  _c(
                    "h6",
                    {
                      staticClass: "align-self-center cursor-pointer ml-1 mb-0",
                    },
                    [_vm._v(" +42 ")]
                  ),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }