var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.name || _vm.amount
    ? _c(
        "b-card",
        { staticClass: "card-congratulation-medal" },
        [
          _c("h5", [_vm._v("🎉 " + _vm._s(_vm.name) + "!")]),
          _c("b-card-text", [_vm._v(" Total em pedidos aprovados neste mês ")]),
          _c("h3", { staticClass: "mb-75 text-success mt-2 pt-50" }, [
            _vm._v(" R$ " + _vm._s(_vm.amount ? _vm.amount : "0") + " "),
          ]),
          _c(
            "a",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { 400: true },
                },
              ],
              staticClass: "btn btn-primary",
              attrs: { href: "/orders" },
            },
            [_vm._v(" Ver vendas ")]
          ),
          _c("b-img", {
            staticClass: "congratulation-medal",
            attrs: {
              src: require("@/assets/images/illustration/badge.svg"),
              alt: "Medal Pic",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }